import '@/styles/dmq-home-flash-sale-swiper.scss';
import Swiper from '@/swiper';

export default class DmqHomeFlashSaleSwiper extends Swiper {
  constructor() {
    super('.dmq-home-flash-sale-swiper-container', {
      containerModifierClass: 'dmq-home-flash-sale-swiper-container-',
      slideClass: 'dmq-home-flash-sale-swiper-slide',
      slideActiveClass: 'dmq-home-flash-sale-swiper-slide-active',
      slideDuplicateActiveClass: 'dmq-home-flash-sale-swiper-slide-duplicate-active',
      slideVisibleClass: 'dmq-home-flash-sale-swiper-slide-visible',
      slideDuplicateClass: 'dmq-home-flash-sale-swiper-slide-duplicate',
      slideNextClass: 'dmq-home-flash-sale-swiper-slide-next',
      slideDuplicateNextClass: 'dmq-home-flash-sale-swiper-slide-duplicate-next',
      slidePrevClass: 'dmq-home-flash-sale-swiper-slide-prev',
      slideDuplicatePrevClass: 'dmq-home-flash-sale-swiper-slide-duplicate-prev',
      wrapperClass: 'dmq-home-flash-sale-swiper-wrapper',
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.dmq-home-flash-sale-swiper-button-next',
        prevEl: '.dmq-home-flash-sale-swiper-button-prev',
      },
      pagination: {
        el: '.dmq-home-flash-sale-swiper-pagination',
        clickable: true,
        bulletClass: 'dmq-home-flash-sale-swiper-pagination-bullet',
        bulletActiveClass: 'dmq-home-flash-sale-swiper-pagination-bullet-active',
        modifierClass: 'dmq-home-flash-sale-swiper-pagination-',
        currentClass: 'dmq-home-flash-sale-swiper-pagination-current',
        totalClass: 'dmq-home-flash-sale-swiper-pagination-total',
        hiddenClass: 'dmq-home-flash-sale-swiper-pagination-hidden',
        progressbarFillClass: 'dmq-home-flash-sale-swiper-pagination-progressbar-fill',
        clickableClass: 'dmq-home-flash-sale-swiper-pagination-clickable',
      },
    });
  }
}
