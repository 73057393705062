import './common-styles';
import '@/styles/dmq-retailer-swiper.scss';
import '@/styles/dmq-products-section.scss';
import '@/styles/dmq-product-list.scss';
import '@/styles/dmq-product-card.scss';
import '@/styles/dmq-home.scss';
import '@/styles/dmq-home-retailer-swiper.scss';

import './common-code';
import Vue from 'vue';
import DmqRetailerSwiper from '@/components/DmqRetailerSwiper';
import DmqHomeRetailerSwiper from '@/components/DmqHomeRetailerSwiper';
import DmqMap from '@/components/DmqMap';
import DmqHomeFlashSaleSwiper from '@/components/DmqHomeFlashSaleSwiper';
import DmqRemainingTime from '@/components/DmqRemainingTime';

Vue.config.productionTip = false;

const drs = new DmqRetailerSwiper();
const dhrs = new DmqHomeRetailerSwiper();
const dhfss = new DmqHomeFlashSaleSwiper();


document.querySelectorAll<HTMLElement>('.dmq-home-flash-sale-remaining-time').forEach((el) => {
  const _ = new DmqRemainingTime(el);
});

const contactMapEl = document.getElementById('contact-map') as HTMLElement;
if (contactMapEl) {
  const _ = new DmqMap(contactMapEl);
}

export {
  drs,
  dhrs,
  dhfss,
};
