import '@/styles/dmq-home-retailer-swiper.scss';
import Swiper from '@/swiper';

export default class DmqHomeRetailerSwiper extends Swiper {
  constructor() {
    super('.dmq-home-retailer-swiper-container', {
      containerModifierClass: 'dmq-home-retailer-swiper-container-',
      slideClass: 'dmq-home-retailer-swiper-slide',
      slideActiveClass: 'dmq-home-retailer-swiper-slide-active',
      slideDuplicateActiveClass: 'dmq-home-retailer-swiper-slide-duplicate-active',
      slideVisibleClass: 'dmq-home-retailer-swiper-slide-visible',
      slideDuplicateClass: 'dmq-home-retailer-swiper-slide-duplicate',
      slideNextClass: 'dmq-home-retailer-swiper-slide-next',
      slideDuplicateNextClass: 'dmq-home-retailer-swiper-slide-duplicate-next',
      slidePrevClass: 'dmq-home-retailer-swiper-slide-prev',
      slideDuplicatePrevClass: 'dmq-home-retailer-swiper-slide-duplicate-prev',
      wrapperClass: 'dmq-home-retailer-swiper-wrapper',
      slidesPerView: 2,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        400: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: '.dmq-home-retailer-swiper-button-next',
        prevEl: '.dmq-home-retailer-swiper-button-prev',
      },
    });
  }
}
